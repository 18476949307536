import React from 'react'
import classes from './PMPformulas.module.css'
import blogClasses from '../../PMPblog.module.css'
import Container from '../../../../UI/Container/Container'
import Heading from '../../../../UI/Heading/Heading'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import InfoIcon from '../../../../../images/icons/infoPurple.svg'
import Tooltip from '../../../../UI/Tooltip/Tooltip'
import posts from '../../../../../../data/blog/posts'
import SEO from '../../../../seo'
import Signature from '../Signature/Signature'

const post = posts.filter(post => post.link === 'pmp-formulas-cheat-sheet')[0]

const PMPformulas = () => (
  <Container width={1060}>
    <SEO
      title={`PMP Blog | ${post.title}`}
      keywords={[
        `PMP formulas`,
        `PMP Cheat Sheet`,
        `PMP Formula-Based Questions`,
        `PMP Exam`,
        `Project Management`
      ]}
      description="PMP Formulas You Have To Know. Ultimate PMP Formulas Cheat Sheet. Prepare for the PMP Exam with this List of Formulas. Answer PMP formula-based questions."
    ></SEO>
    <Heading text={post.title} />
    <div className={blogClasses.Image}>{post.images[0]}</div>
    <p></p>
    <p>
      When taking the PMP exam, you can expect to answer different types of
      questions: formula-based questions, situation-based questions,
      definition-based questions, ITTO-based questions, interpretational
      questions and PMP ethics questions.
    </p>
    <p>
      Different types of questions need a different approach. For me personally,
      it's easier to calculate, and formula-based questions were my favorite
      during the preparation for the exam. I made a list of all formulas used in
      project management and learned them by heart. I will share this list and
      also, I will show you my cheat sheet of the formulas that I actually used
      during the exam.
    </p>
    <TableContainer component={Paper} className={classes.Table}>
      <Table size="small" aria-label="a dense table">
        <TableHead className={classes.TableHead}>
          <TableRow>
            <TableCell align="center">Formula</TableCell>
            <TableCell align="center">Definition</TableCell>
            <TableCell align="center">Abbreviations</TableCell>
            <TableCell align="center">Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {post.formulas.map((formula, i) => (
            <TableRow key={i}>
              <TableCell align="left">
                <span className={classes.Formula}>{formula.formula}</span>
              </TableCell>
              <TableCell align="left" className={classes.Definition}>
                <span>{formula.definition}</span>
              </TableCell>
              <TableCell align="left">
                {formula.abbreviations.map((abb, i) => (
                  <span key={i} className={classes.Abbreviation}>
                    {abb}
                    <br />
                  </span>
                ))}
              </TableCell>
              <TableCell align="center">
                {formula.notes ? (
                  <Tooltip
                    title={formula.notes.map((note, i) => (
                      <span key={i}>
                        {note}
                        <br />
                      </span>
                    ))}
                  >
                    <div className={classes.Info}>
                      <img
                        src={InfoIcon}
                        className={classes.InfoIcon}
                        alt="info icon"
                      />
                    </div>
                  </Tooltip>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <p></p>
    <p>
      This is the most complete list of formulas I could make for myself. I knew
      all of them and I definitely recommend you learning them all without
      exception. However, some of them are used more often than others.{' '}
    </p>
    <p>
      On the PMP exam, it's highly effective to write a cheat sheet as soon as
      the exam starts. It's a concise set of notes used for quick reference. In
      my cheat sheet I included the most commonly used formulas. From my
      personal experience, all the formulas that I needed on the exam were on
      this list. But this is only my experience and you never know what sorts of
      questions you will have and what formulas you will have to use. That's
      why, even though most probably you will use the formulas mentioned in my
      cheat sheet, you should not limit yourself to only them, but learn all the
      formulas used in project management.
    </p>
    <div className={classes.SubHeading}>
      <center>
        <h2>PMP Formulas Cheat Sheet</h2>
      </center>
    </div>
    <div className={[blogClasses.List, classes.CheatSheet].join(' ')}>
      <Paper elevation={3}>
        <ul>
          {post.cheatSheet.map((cheat, i) => (
            <li key={i}>
              <span>{cheat}</span>
            </li>
          ))}
        </ul>
      </Paper>
    </div>
    <Signature date={post.date} author={post.author} />
  </Container>
)

export default PMPformulas
