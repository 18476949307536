import React from 'react'
import classes from './Signature.module.css'

const signature = props => (
  <div className={classes.Container}>
    <div>
      Author: <span className={classes.Item}>{props.author}</span>
    </div>
    <div>
      Date: <span className={classes.Item}>{props.date}</span>
    </div>
  </div>
)

export default signature
