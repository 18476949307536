import React from 'react'
import Layout from '../../hoc/Layout/Layout'
import PMPformulas from '../../components/Pages/PMPblog/articles/PMPformulas/PMPformulas'

export default () => {
  return (
    <Layout>
      <PMPformulas />
    </Layout>
  )
}
